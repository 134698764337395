// import decodeJwt from "jwt-decode";

import * as firebase from "firebase/app";
import "firebase/auth";


var firebaseConfig = {
  apiKey: "AIzaSyBNB-ddeE2PnKQFkNgeqcBs9QU1nyiD15c",
  authDomain: "biliar-7bf00.firebaseapp.com",
  databaseURL: "https://biliar-7bf00-dev.firebaseio.com",
  projectId: "biliar-7bf00",
  storageBucket: "biliar-7bf00.firebaseio.com",
  messagingSenderId: "536596634417",
  appId: "1:536596634417:web:2ed7134bf292ffcd556e10",
  measurementId: "G-506RWBVDXW",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('profile');
provider.addScope('email');

//provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

const providerApple = new firebase.auth.OAuthProvider("apple.com");
export default {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then((result) => {
        result.user.getIdToken().then((firebase_token) => {
          sessionStorage.setItem("firebase_token", firebase_token);
          sessionStorage.setItem("username", username);
          sessionStorage.setItem("userid", result.user.uid);

          window.location.reload(false);          
        });
      })
      .catch(function (error) {     
        sessionStorage.removeItem("firebase_token");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("userid");

      });
  },
  // called when the user clicks on the logout button
  logout: () => {

    sessionStorage.removeItem("firebase_token");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("userid");
    firebase.auth().signOut();
    window.location.reload(false);
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    //console.log(sessionStorage.getItem("firebase_token"));
    return sessionStorage.getItem("firebase_token") ? true : false;

  },

  loginwithGoogle: async () => {

    await firebase.auth().signInWithPopup(provider)
      .then(async (result) => {
        const isNewUser = result.additionalUserInfo.isNewUser;

        if (!isNewUser) {
          result.user.getIdToken().then((firebase_token) => {          
            sessionStorage.setItem("firebase_token", firebase_token);
            sessionStorage.setItem("username", result.user.providerData[0].email == null ? result.user.providerData[0].providerId : result.user.providerData[0].email);
            sessionStorage.setItem("userid", result.user.uid);
            sessionStorage.setItem("isNewUser", isNewUser);

            window.location.reload(false);
          });

        }
        else {          
          firebase.auth().currentUser.delete();
        }

      }).catch((error) => {       
        sessionStorage.removeItem("firebase_token");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("userid");        
      });
  },
  loginwithApple: async () => {

    await firebase.auth().signInWithPopup(providerApple)
      .then(async (result) => {

        const isNewUser = result.additionalUserInfo.isNewUser;

        if (!isNewUser) {
          result.user.getIdToken().then((firebase_token) => {

            sessionStorage.setItem("firebase_token", firebase_token);
            sessionStorage.setItem("username", result.user.providerData[0].email == null ? result.user.providerData[0].providerId : result.user.providerData[0].email);
            sessionStorage.setItem("userid", result.user.uid);

            window.location.reload(false);
          });
        }
        else {
          firebase.auth().currentUser.delete();
        }

      }).catch((error) => {
        sessionStorage.removeItem("firebase_token");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("userid");
      });
  },
 
};
