import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
//import Avatar from "react-avatar";
import Grid from "@material-ui/core/Grid";

import MaterialTable, { MTableToolbar } from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import api from "../connect1";

// MUI Stuff
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import authProvider from "../authProvider1";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};


function Users() {
    //#region  init page
    var columns = [
        // { title: "UserId", field: "userid", hidden: true },
        {
            title: "UserId",
            field: "userid",
            render: (rowData) => {
                return (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: rowData.userid.substring(0, 50).concat("..."),
                        }}
                    />
                );
            },
        },
        {
            title: "UserName/Provide/Email",
            field: "username",
            filtering: false,
            render: (rowData) => {
                return (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: rowData.username.substring(0, 100).concat("..."),
                        }}
                    />
                );
            },
        },
    ];
    //#endregion

    //#region page load
    const [data, setData] = useState([]);

    useEffect(() => {
        var username = sessionStorage.getItem("username1");
        var userid = sessionStorage.getItem("userid1");
        setData([
            {
                "userid": userid,
                "username": username
            }
        ]);
    }, []);

    //#endregion

    //#region  page insert
    const [showDialog, setShowDialog] = useState(false);
    let [loading, setLoading] = useState(false);

    const handleClose = () => {
        setShowDialog(false);
    };

    //#endregion

    //#region page delete
    const handleRowDelete = async (oldData, resolve) => {
        let headers = `${sessionStorage.getItem("firebase_token1")}`;
        setLoading(true);      

        api().post("", { idToken: headers })
            .then((res) => {
                NotificationManager.success("Delete user success", "Deleted");              
                setLoading(false);
                setTimeout(() => {                    
                    authProvider.logout();
                }, "500");              

            })
            .catch((error) => {   
                NotificationManager.error("Delete user Fail. Requied Logout", "Deleted", 5000);
                setLoading(false);
                setTimeout(() => {                    
                    authProvider.logout();
                }, "5000");     
            });
    };

    const handleCloseDelete = () => {
        setShowDialog(false);
        setShowDialogDelete(false);
    };

    const [dataMapDelete, setDataMapDelete] = useState({
        id: "",
    });

    const [showDialogDelete, setShowDialogDelete] = useState(false);

    const handleSubmitDelete = (event) => {
        loading = true;
        event.preventDefault();
        new Promise((resolve) => {
            handleRowDelete(dataMapDelete, resolve);
        });
        setShowDialogDelete(false);
    };

    //#endregion 

    const mapNewToState = (newUpdate) => {
        setDataMapDelete((prevState) => ({
            ...prevState,
            userid: newUpdate.userid,
            index: newUpdate.tableData.userid,
        }));
    };

    //#region  Export
    const byString = (o, s) => {
        if (!s) {
            return;
        }

        s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
        s = s.replace(/^\./, ""); // strip a leading dot
        var a = s.split(".");
        for (var i = 0, n = a.length; i < n; ++i) {
            var x = a[i];
            if (o && x in o) {
                o = o[x];
            } else {
                return;
            }
        }
        return o;
    };

    //#endregion

    return (
        <div className="App">
            <Grid container spacing={1}>
                <Grid item xs={0}></Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title="User Account"
                        columns={columns}
                        data={data}
                        icons={tableIcons}
                        isLoading={loading}
                        actions={[
                            {
                                icon: () => <DeleteOutline />,
                                tooltip: "Delete",
                                onClick: (event, rowData) => {
                                    mapNewToState(rowData);
                                    setShowDialogDelete(true);
                                },

                            },
                        ]}
                        options={{
                            exportButton: false,
                            filtering: false,
                            paging: false,
                            search: false,
                            actionsColumnIndex: -1,
                            headerStyle: {
                                backgroundColor: "#e6f0ff",
                            },
                        }}
                        localization={{
                            header: {
                                actions: "",
                            },
                        }}
                        components={{
                            Toolbar: (props) => (
                                <div>
                                    <MTableToolbar {...props} />

                                </div>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={0}></Grid>
            </Grid>

            <Dialog
                open={showDialogDelete}
                onClose={handleCloseDelete}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Are you sure you want to delete your account and all data?</DialogTitle>
                <DialogActions>
                    <Button
                        onClick={handleCloseDelete}
                        variant="contained"
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmitDelete}
                        variant="contained"
                        color="secondary"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <NotificationContainer />
        </div>
    );
}

export default Users;
