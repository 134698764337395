import axios from "axios";

const api = () => {
  let headers = `Bearer ${sessionStorage.getItem("firebase_token")}`;
  return axios.create({
    baseURL: `https://identitytoolkit.googleapis.com/v1/accounts:delete?key=AIzaSyBNB-ddeE2PnKQFkNgeqcBs9QU1nyiD15c`,   
     headers: {
    //   //Authorization: headers,      
    //   'Access-Control-Allow-Origin': '*',
       'Content-Type': 'application/json',
     },
    
  });
};
export default api;
