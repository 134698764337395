import React,{ useEffect } from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import MyMenu from "./MyMenu";
import MyMenu1 from "./MyMenu1";
//import MyMenu2 from "./MyMenu2";
import NotFound from "./NotFound";

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function Example() {
  useEffect(() => {
    document.title = 'My Page Title';
  }, []);
}
ReactDOM.render(   
  <React.StrictMode>
    {/* <BrowserRouter>
      <MyMenu />
    </BrowserRouter> */}
    <Router>
      <Switch>
     
        <Route exact path='/bia' component={MyMenu}>                
        </Route>
        <Route exact path='/nw1' component={MyMenu1}>
        
        </Route>
        {/*<Route exact path='/nw3test' component={MyMenu2}>
        </Route>*/}
          <Route exact path='*' component={NotFound}>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
