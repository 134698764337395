import React from "react";

import { ReactComponent as Logo } from './rolling_load1.svg';


export default function LoaderSignGoogle() {
  return (
    <div>
      {/* Logo is an actual React component */}
      <Logo style={{ marginTop: "280px" }} />
    </div>
  );
}   