import axios from "axios";

const api = () => {
  let headers = `Bearer ${sessionStorage.getItem("firebase_token1")}`;
  return axios.create({
    baseURL: `https://identitytoolkit.googleapis.com/v1/accounts:delete?key=AIzaSyCUA5djReCQu4w6G7mXuaVq9u5GuWPLyF8`,   
     headers: {
    //   //Authorization: headers,      
    //   'Access-Control-Allow-Origin': '*',
       'Content-Type': 'application/json',
     },
    
  });
};
export default api;
