import HomeIcon from "@material-ui/icons/Home";
import StorageIcon from "@material-ui/icons/Storage";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TimePushIcon from "@material-ui/icons/Timelapse";

// PAGES
import Dashboard from "../pages/users1";

export const menuItem = [
  {
    key: "home",
    label: "Home",
    icon: HomeIcon,    
    exact: true,
    component: Dashboard,
  },  
];
