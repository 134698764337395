// import decodeJwt from "jwt-decode";
import * as firebaseNinja from "firebase/app";
import "firebase/auth";

var firebaseConfigNinja = {
  apiKey: "AIzaSyCUA5djReCQu4w6G7mXuaVq9u5GuWPLyF8",
  authDomain: "shadow-ninja-arashi.firebaseapp.com",
  databaseURL: "https://shadow-ninja-arashi.firebaseio.com",
  projectId: "shadow-ninja-arashi",
  storageBucket: "shadow-ninja-arashi.appspot.com",
  messagingSenderId: "905012875298",
  appId: "1:905012875298:web:fff0527ad282d7cf98ba7b",
  measurementId: "G-R63ZFFE4L0"
};

// Initialize Firebase
firebaseNinja.initializeApp(firebaseConfigNinja,"ninja");

const provider = new firebaseNinja.auth.GoogleAuthProvider();
provider.addScope('profile');
provider.addScope('email');
//provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

const providerApple = new firebaseNinja.auth.OAuthProvider("apple.com");
export default {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    firebaseNinja.app("ninja")
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then((result) => {
        result.user.getIdToken().then((firebase_token) => {
          sessionStorage.setItem("firebase_token1", firebase_token);
          sessionStorage.setItem("username1", username);
          sessionStorage.setItem("userid1", result.user.uid);

          window.location.reload(false);
        });
      })
      .catch(function (error) {       
        sessionStorage.removeItem("firebase_token1");
        sessionStorage.removeItem("username1");
        sessionStorage.removeItem("userid1");

      });
  },
  // called when the user clicks on the logout button
  logout: () => {

    sessionStorage.removeItem("firebase_token1");
    sessionStorage.removeItem("username1");
    sessionStorage.removeItem("userid1");
    firebaseNinja.auth().signOut();
    window.location.reload(false);
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {   
    return sessionStorage.getItem("firebase_token1") ? true : false;

  },

  loginwithGoogle: async () => {

    await firebaseNinja.app("ninja").auth().signInWithPopup(provider)
      .then(async (result) => {
        const isNewUser = result.additionalUserInfo.isNewUser;

        if (!isNewUser) {
          result.user.getIdToken().then((firebase_token) => {
            sessionStorage.setItem("firebase_token1", firebase_token);
            sessionStorage.setItem("username1", result.user.providerData[0].email == null ? result.user.providerData[0].providerId : result.user.providerData[0].email);
            sessionStorage.setItem("userid1", result.user.uid);
            sessionStorage.setItem("isNewUser1", isNewUser);

            window.location.reload(false);
          });

        }
        else {
          firebaseNinja.app("ninja").auth().currentUser.delete();
        }

      }).catch((error) => {      
        sessionStorage.removeItem("firebase_token1");
        sessionStorage.removeItem("username1");
        sessionStorage.removeItem("userid1");
      });
  },
  loginwithApple: async () => {

    await firebaseNinja.app("ninja").auth().signInWithPopup(providerApple)
      .then(async (result) => {

        const isNewUser = result.additionalUserInfo.isNewUser;
        if (!isNewUser) {
          result.user.getIdToken().then((firebase_token) => {           
            sessionStorage.setItem("firebase_token1", firebase_token);
            sessionStorage.setItem("username1", result.user.providerData[0].email == null ? result.user.providerData[0].providerId : result.user.providerData[0].email);
            sessionStorage.setItem("userid1", result.user.uid);

            window.location.reload(false);
          });
        }
        else {
          firebaseNinja.app("ninja").auth().currentUser.delete();
        }
      }).catch((error) => {      
        sessionStorage.removeItem("firebase_token1");
        sessionStorage.removeItem("username1");
        sessionStorage.removeItem("userid1");
      });
  },

};
