import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Collapse from "@material-ui/core/Collapse";
import { useTheme } from "@material-ui/core/styles";
import { Switch, Route, Link } from "react-router-dom";

// ICON
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";

import authProvider from "./authProvider1";
import { useStyles } from "./style/MenuStyle";
import { menuItem } from "./config/MenuData1";

import SignIn from "./pages/signin1";
import logo from "./img/logo1.png";

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openState, setOpenState] = React.useState({ open: {} });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const openTop = Boolean(anchorEl);

  document.title ="Delete Account on Ninja warrior game";
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = '/logo1.ico';

  
  // Check Auth
  if (!authProvider.checkAuth()) {
    return <SignIn />;
  } else {
    // console.log("+++ Memu: Auth OK!");
  }

  const handleExpand = (key) => () => {
    setOpenState({ [key]: !openState[key] });
  };

  const drawer = (
    <React.Fragment>
      <div className={classes.toolbar}>
        <div className={classes.toolbarColor}>      
            <img src={logo} alt="Logo" />         
        </div>
      </div>
      <Divider />
      <List>
        {menuItem.map(({ key, label, icon: Icon, items, path }) => {
          const open = openState[key] || false;
          return (
            <React.Fragment key={key}>
              <ListItem
                button
                component={Link}
                to={path ? path : "#"}
                onClick={path ? null : handleExpand(key)}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
                {items ? open ? <ExpandLess /> : <ExpandMore /> : ""}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {items?.map(
                    ({
                      key: childKey,
                      label: childLabel,
                      icon: ChildIcon,
                      path: childPath,
                    }) => (
                      <ListItem
                        key={childKey}
                        button
                        className={classes.nested}
                        component={Link}
                        to={childPath}
                      >
                        <ListItemIcon>
                          <ChildIcon />
                        </ListItemIcon>
                        <ListItemText primary={childLabel} />
                      </ListItem>
                    )
                  )}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
      </List>
      <Divider />
    </React.Fragment>
  );

  const routeMenu = menuItem.map(({ key, path, exact, component }) => {
    return (
      <React.Fragment key={key}>
        {exact ? (
          <Route exact path={path} component={component} />
        ) : (
          <Route path={path} component={component} />
        )}
      </React.Fragment>
    );
  });

  const routeSubMenu = menuItem.map(({ items }) => {
    const subMenu = items?.map(
      ({ path: childPath, component: childComponent }) => {
        return <Route path={childPath} component={childComponent} />;
      }
    );
    return subMenu;
  });

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = () => {
    setAnchorEl(null);
    authProvider.logout();    
  };

  // const onSettingClick = () => {
  //   setAnchorEl(null);
  //   authProvider.test_firebase();
  //   console.log("OnSetting Click!");
  // };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            DELETE ACCOUNT & ALL DATA
          </Typography>

          <section className={classes.menuTop}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openTop}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={onSettingClick}>Setting</MenuItem> */}
              <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
            </Menu>
          </section>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          {/* <Route path="/signin" component={SignIn} /> */}
          {routeSubMenu}
          {routeMenu}
        </Switch>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
