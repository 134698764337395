import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
// import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AuthProvider from "../authProvider1";
import logo from "../img/logo1.png";
import googleLogo from "../img/google.png";
import appleLogo from "../img/apple.png";
import emailLogo from "../img/email.png";
import Avatar from "@material-ui/core/Avatar";

import LoaderSignGoogle from "../components/LoaderSignGoogle";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://tohsoft.com/">
        TOHSoft
      </Link>{" "}
      {/* {new Date().getFullYear()} */}
      {"2024."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  text: {
    fontSize: "14pt",
    // fontWeight:"bold",
    backgroundColor: "transparent",
    padding: "15pt 0 0 0"
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  function handleEmailChange(e) {
    setUsername(e.target.value);
  }
  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  let [isLoading, setLoading] = React.useState(false);

  function handleLoginClick(e) {
    try {
      setLoading(true);
      e.preventDefault();
      if (username === "") {
        setLoading(false);
        NotificationManager.error("A email is required", "Sign in", 2000);
      }
      if (password === "") {
        setLoading(false);
        NotificationManager.error("A password is required", "Sign in", 2000);
      }
      else {
        AuthProvider.login({
          username: username,
          password: password,
        }).then(async (result) => {
          setLoading(true);
          setTimeout(() => {
            if (typeof result === "undefined") {
              setLoading(false);
              NotificationManager.error("Account does not exist", "Sign in failed", 5000);
            }
            else {
              setLoading(false);
            }
          }, "3000");

        })
          .catch((err) => {
            setLoading(false);
            NotificationManager.error(err, "Sign in");
          });
      }
    }
    catch (error) {
      setLoading(false);
      NotificationManager.error(error, "Sign in");
    }
  }

  function handleLoginGoogleClick(e) {
    try {
      e.preventDefault();
      AuthProvider.loginwithGoogle().then(async (result) => {
        setLoading(true);
        setTimeout(() => {
          if (typeof result === "undefined") {
            setLoading(false);
            NotificationManager.error("Account does not exist", "Sign in failed", 5000);
          }
          else {
            setLoading(false);
          }
        }, "5000");

      })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err, "Sign in");
        });
    }
    catch (error) {
      setLoading(false);
      NotificationManager.error(error, "Sign in");
    }
  }

  function handleLoginAppleClick(e) {
    e.preventDefault();
    AuthProvider.loginwithApple().then((result) => {
      setLoading(true);
      setTimeout(() => {
        if (typeof result === "undefined") {
          setLoading(false);
          NotificationManager.error("Account does not exist", "Sign in", 5000);
        }
        else {
          setLoading(false);
        }
      }, "5000");

    })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error(err, "Sign in");
      });
  }


  return (
    <Container component="main" maxWidth="xs">
      <NotificationContainer />
      {isLoading ? <LoaderSignGoogle /> :
        (
          <div>
            <CssBaseline />
            <div className={classes.paper}>
              <img src={logo} alt="Logo"></img>
              <label className={classes.text} >Please sign in to delete your account</label>
              <form className={classes.form} noValidate >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={username}
                  onChange={handleEmailChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  startIcon={
                    <Avatar
                      src={
                        emailLogo
                      }
                    />
                  }
                  className={classes.submit}
                  onClick={handleLoginClick}
                >
                  Sign In With Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  startIcon={
                    <Avatar
                      src={
                        googleLogo
                      }
                    />
                  }
                  className={classes.submit}
                  onClick={handleLoginGoogleClick}
                >
                  Sign In with Google
                </Button>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  startIcon={
                    <Avatar
                      src={
                        appleLogo
                      }
                    />
                  }
                  className={classes.submit}
                  onClick={handleLoginAppleClick}
                >
                  Sign In with Apple
                </Button>
              </form>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </div>
        )

      }
    </Container>
  );
}
